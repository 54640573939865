.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  height: auto;
}

.home-content {
  flex: 1;
  padding: 20px;
}

.home-description {
  font-size: 1.4rem;
  margin-bottom: 0;
  color: #666;
  text-align: center;
  margin-bottom: 60px;
}

body.dark .home-description {
  color: white;
}

body.dark .home-nav-button {
  background-color: #29443c;
  border-color: rgba(200, 200, 200, 0.5);
  color: white;
}

body.dark .home-nav-button:hover {
  background-color: #5c7a7a;
  border-color: rgba(200, 200, 200, 0.7);
}

.home-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  width: 100%;
}

.home-button-wrapper {
  overflow: hidden;
}

.home-nav-button {
  width: 150px;
  height: 150px;
  font-size: 1.4rem;
  padding: 10px;
  color: black;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
  background-color: #708a8a;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  line-height: 1.2;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.home-nav-button:hover {
  background-color: rgba(60, 179, 113, 0.7);
  color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border: 3px solid rgba(255, 255, 255, 0.7);
}

.home-nav-button:focus {
  outline: none;
}

.home-button-wrapper a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .home-container {
    max-height: 100vh;
  }

  .home-title {
    font-size: 2.5rem;
  }

  .home-description {
    font-size: 1rem;
    margin-bottom: 30px;
    margin-top: 5px;
  }

  .home-nav {
    flex-direction: column;
    gap: 20px;
  }

  .home-nav-button {
    width: 150px;
    height: 150px;
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px),
  (min-width: 1024px) and (max-width: 1366px) {
  .home-description {
    font-size: 1.6rem;
    margin-bottom: 80px;
  }

  .home-nav-button {
    width: 180px;
    height: 180px;
    font-size: 1.7rem;
  }

  .home-nav {
    gap: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) and (orientation: landscape) {
  .home-nav {
    flex-direction: row;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .home-nav {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .home-title {
    font-size: 2rem;
  }

  .home-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  .home-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
  }

  .home-nav-button {
    width: 100%;
    max-width: 250px;
    height: 100px;
    font-size: 1rem;
  }
}
