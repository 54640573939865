.footer {
  padding: 5px;
  background-color: #f5f5f5;
  text-align: center;
  font-size: 15px;
  width: 100%;
  color: #778899;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  height: 50px;
}

body.dark .footer {
  background-color: #284444;
}

@media (max-width: 600px) {
  .footer {
    font-size: 0.75rem;
    text-align: center;
  }
}
