.contact-form-container {
  width: 75%;
  max-width: 75%;
  background-color: #90a6a6;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.contact-title {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-email {
  text-align: center;
  font-size: 18px;
  margin-top: 30px;

  a {
    color: rgb(19, 128, 157);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: rgb(12, 88, 105);
    }
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 75%;
}

.form-field {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 30px;
  width: 100%;
}

.form-label {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: rgb(12, 88, 105);
    outline: none;
  }
}

.form-textarea {
  resize: vertical;
  height: 175px;
}

.form-button-container {
  width: 100%;
  display: flex;
  justify-content: center !important;
}

.form-button {
  padding: 12px 24px;
  background-color: #2f4f4f;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  max-width: 100px;
  width: 100%;
  margin-top: 20px;

  &:hover {
    background-color: #243c3c;
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
}

body.dark {
  .contact-form-container {
    background-color: #003333;
  }

  .form-label {
    color: white;
  }

  .form-input,
  .form-textarea {
    background-color: #2f4f4f;
  }
}


@media (max-width: 768px) {
  .contact-form-container {
    width: 80%;
    max-width: 85%;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .contact-title {
    font-size: 1rem;
  }

  .form-label {
    font-size: 14px;
  }

  .form-input,
  .form-textarea {
    font-size: 14px;
    padding: 5px;
  }

  .form-button {
    font-size: 14px;
  }
}
