.info-card-skills {
  background-color: #90a6a6;
  color: #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

body.dark {
  .info-card-skills {
    background-color: #003333;
  }

  .skill-group-title,
  .soft-skills,
  .skill-icons i {
    color: white;
  }
}

.info-card {
  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  h5 {
    margin-bottom: 8px;
    font-size: 1.2rem;
  }

  p {
    line-height: 1.6;
  }
}

.skill-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;

  i {
    position: relative;
    font-size: 4rem;
    margin: 19px;
    color: #2f4f4f;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1);
    }

    &::after {
      content: attr(title);
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      padding: 5px 8px;
      border-radius: 4px;
      white-space: nowrap;
      opacity: 0;
      transition: opacity 0.3s, bottom 0.3s;
      pointer-events: none;
      font-size: 0.85rem;
    }

    &:hover::after {
      opacity: 1;
    }
  }
}

.skill-group-title {
  display: block;
  font-size: 1.5rem;
  margin: 50px 0 25px;
  text-align: center;
  color: darkslategray;
}

.soft-skills {
  color: darkslategray;
}

@media (max-width: 600px) {
  .info-card-skills {
    width: 80%;
  }

  .skill-group-title {
    font-size: 1.2rem;
  }

  .skill-icons i {
    font-size: 2.6rem;
  }

  .contact-links {
    flex-direction: column;
    align-items: center;
  }

  .soft-skills {
    font-size: 0.9rem;
  }
}
