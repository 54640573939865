.info-card {
  background-color: #90a6a6;
  color: #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  max-width: 950px;
  margin: 10px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 25px;
}

.info-card-project {
  background-color: #90a6a6;
  color: #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  width: 90%;
  max-width: 950px;
  margin: 10px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-bottom: 20px;
}

body.dark .info-card-project {
  background-color: #5c7a7a;
}

body.dark .info-card {
  background-color: #003333;
}

body.dark .project-item {
  background-color: #5c7a7a;
}

body.dark .title-project {
  color: white;
}

.project-item {
  background-color: #f2fdf2;
}

.info-card-project h3 {
  font-size: 1.7rem;
  margin-bottom: 10px;
}

.info-card-project p {
  line-height: 1.8;
  font-size: 1rem;
}

.info-card-project h5 {
  margin-bottom: 8px;
  font-size: 1.6rem;
}

.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  padding: 10px;
  justify-items: center;
}

.project-item {
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
  background-color: #94b2b2;
}

.project-item img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
}

.project-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  margin-top: 10px;
}

.project-description {
  color: #696969;
}

.project-container {
  color: #2f4f4f;
}

.project-link {
  color: #2f4f4f;
  text-decoration: none;
  margin-bottom: 15px;
}

.details {
  font-size: 1.3rem !important;
}

.custom-link {
  color: #673147;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.2rem;
}


.custom-link-bottom {
  color: #673147;
  text-decoration: none;
  display: block;
  border: 1px solid transparent;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 1.2rem;
}

body.dark .custom-link-bottom {
  color:	#FFB6C1;
}

body.dark .custom-link {
  color: 	#FFB6C1;
}

.custom-link:hover {
  text-decoration: underline;
  color: darkslategray;
}

body.dark .project-name {
  color: white;
}

@media (max-width: 768px) {
  .project-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .project-item {
    padding: 20px;
    width: 80%;
    max-width: none;
  }

  .project-item img {
    height: 200px;
  }

  .info-card-project {
    width: 80%;
  }

  .info-card-project h3 {
    font-size: 1.8rem;
  }

  .info-card-project h5 {
    font-size: 1.3rem;
  }

  .details {
    font-size: 16px !important;
  }
}
