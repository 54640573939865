html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: #2f4f4f;
  font-family: "Ubuntu", sans-serif;
  box-sizing: border-box;
}

body.light {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%2366cdaa' fill-opacity='0.11'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
  color: black;
}

body.dark {
  background-color: #2f4f4f;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='%2366cdaa' fill-opacity='0.11'%3E%3Cpolygon fill-rule='evenodd' points='8 4 12 6 8 8 6 12 4 8 0 6 4 4 6 0 8 4'/%3E%3C/g%3E%3C/svg%3E");
  color: white;
}

body.dark .theme-toggle {
  background-color: #d3d3d3;
  color: black;
}

body.dark .theme-toggle:hover {
  background-color: #666;
}

body.dark .h3,
body.dark .h4,
body.dark .h5,
body.dark .p {
  color: white;
}

body.dark .layout-name,
body.dark .layout-sub {
  color: white;
}

.main-container {
  flex: 1;
}

.App {
  text-align: center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layout {
  text-align: center;
  margin: 0;
  padding-top: 0;
  position: relative;
  flex: 1;
}

.layout-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layout-logo {
  width: 200px;
  height: auto;
  display: block;
  margin: 0 auto 10px;
  border-radius: 50%;
}

.layout-name .layout-sub {
  color: #666;
  margin-bottom: 5px;
}

.layout-name {
  font-size: 36px;
}

.layout-sub {
  font-size: 22px;
}

.layout > .layout-content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}



@media (max-width: 768px) {
  .project-item {
    width: 180px;
  }

  .footer-heading {
    font-size: 0.9rem;
  }
}

@media (max-width: 600px) {
  .layout-logo {
    width: 120px;
    height: auto;
  }

  .layout-name {
    font-size: 1.2rem;
  }

  .layout-sub {
    font-size: 1rem;
  }


  .nav-link {
    margin: 0 10px;
  }
}
