body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.header-container.home-page {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 6px 8px;
  width: 96%;
  position: relative;
  overflow: visible;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 16px;
  width: 96%;
}

.header {
  align-items: right;
  padding: 10px 45px;
  width: 95%;
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0;
}

.navbar-container {
  display: flex;
  align-items: center;
}

.weather-info {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #ffffff;
  padding: 5px 5px;
  border-radius: 8px;
  box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s, box-shadow 0.2s;
  max-width: fit-content;
  margin-left: auto;
  margin-right: 15px;
  flex-shrink: 1;
  white-space: nowrap;
  height: 36px;
  margin-top: 5px;
}

body.dark .weather-info {
  background-color: #5c7a7a;
}

.weather-info:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
}

.weather-icon {
  font-size: 1.5rem;
  transition: transform 0.2s;
}

.location,
.temperature,
.rain-chance {
  margin: 0;
  color: #333;
  font-size: 0.9rem;
  font-weight: 400;
}

.location {
  font-size: 1rem;
  color: #333;
}

.temperature {
  font-weight: 400;
}

.temp {
  font-size: 1rem;
}

.weatherdetail {
  font-size: 0.8rem;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 1001;
  margin-left: 25px;
  margin-top: 25px;
}

.hamburger .line {
  width: 100%;
  height: 5px;
  background-color: #2f4f4f;
  transition: all 0.3s ease;
}

body.dark .hamburger .line {
  background-color: whitesmoke;
}

.nav-links {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 10px;
  background-color: white;
  border: 2px solid darkslategrey;
  border-radius: 8px;
  text-decoration: none;
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 1000;
  width: 250px;
  padding: 15px 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.nav-link {
  margin: 10px 0;
  display: block;
  width: auto;
  text-decoration: none;
  color: #2f4f4f;
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 5px;
}

.nav-link:hover {
  color: #2f4f4f;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

.nav-links.active {
  display: flex;
}

body.dark .nav-links {
  background-color: #2f4f4f;
  border-color: #778899;
  color: whitesmoke;
}

body.dark .nav-link {
  color: whitesmoke;
}

.nav-link:hover {
  color: #006666;
  text-decoration: underline;
  transform: scale(1.05);
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

body.dark .nav-link:hover {
  color: #dcdcdc;
  text-decoration: underline;
  transform: scale(1.05);
}

.hamburger.active .line {
  background-color: white;
}

.hamburger.active .line:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.hamburger.active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .line:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

.theme-switch {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: #ffffff;
  color: #2f4f4f;
  box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.theme-switch:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

body.dark .theme-switch {
  background-color: #5c7a7a;
  color: white;
}

.theme-switch:active {
  transform: scale(0.98);
}

.weather-toggle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  flex-grow: 1;
  min-width: 0;
  overflow: visible;
}

@media (max-width: 768px) {
  .header-container.weather-page .weather-toggle-container {
    display: flex;
    justify-content: flex-end;
    align-items: right;
  }

  .header-container.weather-page .theme-switch {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: -30px;
  }
}

@media (min-width: 601px) {
  .weather-info {
    font-size: 1rem;
    padding: 8px 15px;
    gap: 10px;
  }

  .weather-icon {
    font-size: 2rem;
  }

  .location,
  .temperature,
  .rain-chance {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .header-container.home-page .weather-info,
  .header-container.home-page .theme-switch {
    margin-right: -14px;
    margin-top: 1px;
  }

  .header-container.weather-page.theme-switch {
    margin: 0px !important;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 85%;
    padding: 0 13px;
    box-sizing: border-box;
    margin-top: 14px;
  }

  .hamburger {
    flex-shrink: 0;
    margin-left: 15px;
    margin-top: 30px;
  }

  .weather-toggle-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    flex-grow: 1;
    min-width: 0;
    overflow: visible;
  }

  .weather-info {
    font-size: 0.85rem;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    flex-shrink: 1;
    min-width: 0;
    height: 40px;
    margin-right: 30px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .theme-switch {
    padding: 6px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    background-color: #ffffff;
    color: #2f4f4f;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    min-width: 75px;
    margin-right: 30px;
  }

  body.dark .theme-switch {
    background-color: #5c7a7a;
    color: white;
  }

  .theme-switch:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }

  .theme-switch:active {
    transform: scale(0.98);
  }

  .header-container.weather-page-header {
    display: flex;
    justify-content: space-between;
    align-items: right;
    width: 100%;
    padding: 0 15px;
  }
}
