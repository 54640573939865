body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.weather-title {
  padding: 30px;
  text-align: center;
  color: #333;
}

.location-title {
  text-align: center;
}

.weather-content-wrapper {
  min-height: unset;
  min-width: 100%;
}

.weather-container {
  padding: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  margin-bottom: 30px;
}

.weather-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  max-width: 1200px; 
  width: 100%;
  margin: 0 auto;
}

.weather-card,
.weather-week-card {
  flex: 1 1 calc(16.66% - 15px);
  max-width: calc(16.66% - 15px);
  min-width: 180px;
  min-height: 180px;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center; 
  background-color: #ffffff;
  border: 1px solid #ccc;
}


.weather-card p, 
.weather-week-card p {
  font-size: 1rem;
  margin: 2px;
}

.weather-week-card {
  min-width: 100px;
}


.weather-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

body.dark .weather-card,
body.dark .weather-week-card {
  background-color: #003333;
}

body.dark .location-title,
body.dark .weather-title {
  color: whitesmoke;
}

.weather-icon {
  font-size: 1.5rem;
  margin: 10px 0;
}


.right-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.loading {
  text-align: center;
  font-size: 20px;
}


@media (max-width: 1200px) {
  .weather-card,
  .weather-week-card {
    flex: 1 1 calc(20% - 10px);
    max-width: calc(20% - 10px);
  }
}

@media (max-width: 1024px) {
  .weather-card,
  .weather-week-card {
    flex: 1 1 calc(33.33% - 10px); 
    max-width: calc(33.33% - 10px);
  }
}

@media (max-width: 768px) {
  .weather-card,
  .weather-week-card {
    flex: 1 1 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 600px) {
  .location-title {
    font-size: 18px;
  }

  .weather-title {
    font-size: 17px;
  }

  .weather-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .weather-card,
  .weather-week-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 90vw;
    max-width: 90vw;
    height: 100px !important;
    min-height: unset !important; 
    text-align: left;
  }

  .weather-icon {
    font-size: 2.5rem;
    margin: 0 10px;
  }

  .weatherdetail,
  .temp {
    font-size: 1rem;
  }

  .weather-card p,
  .weather-week-card p {
    margin: 5px 5px;
    padding: 3px;
  }

  .location {
    font-size: medium;
  }
}
